<template>
  <b-button
    variant="primary"
    target="_blank"
    :href="userManagementUrl"
  >
    Go to Account Management Console
    <feather-icon
      icon="ExternalLinkIcon"
      size="16"
      class="ml-50"
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ButtonAccountManagement',
  components: {
    BButton,
  },
  computed: {
    userManagementUrl() {
      const authUrl = `${window.location.protocol}//auth.${window.location.host}/auth`
      const realm = process.env.VUE_APP_AUTH_KEYCLOAK_REALM
      return `${authUrl}/realms/${realm}/account/`
    },
  },
}
</script>

<style scoped>

</style>
