<template>
  <b-card>
    <b-form class="mt-2">
      <!-- alert -->
      <b-col
        v-if="!auth.emailVerified"
        cols="12"
        class="mt-75 mb-2"
      >
        <b-alert
          show
          variant="warning"
          class="mb-50"
        >
          <h4 class="alert-heading">
            Your email address has not been verified. Please check your inbox.
          </h4>
          <div class="alert-body">
            <b-link class="alert-link">
              <feather-icon
                class="mr-25"
                icon="MailIcon"
              />
              Resend email confirmation
            </b-link>
          </div>
        </b-alert>
      </b-col>
      <!--/ alert -->

      <b-row>

        <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              name="username"
              placeholder="(not set)"
              :value="auth.username"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              name="email"
              placeholder="(not set)"
              :value="auth.email"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Photo"
          >
            <b-link id="fileSelect" href="#" class="avatar-link-box" @click="showFileUpload">
              <b-avatar size="72px" :src="avatarFileResult" />
            </b-link>
            <b-form-file
              id="fileInput"
              v-model="avatarFile"
              :state="Boolean(avatarFile)"
              style="display: none"
              accept="image/*"
              @change="imageFileChanged"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="First name"
            label-for="first-name"
          >
            <b-form-input
              v-model.trim="firstName"
              name="firstName"
              placeholder="(not set)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Last name"
            label-for="last-name"
          >
            <b-form-input
              v-model.trim="lastName"
              name="lastName"
              placeholder="(not set)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Job Title"
            label-for="account-jobTitle"
          >
            <b-form-input
              v-model="jobTitle"
              name="jobTitle"
              placeholder="(not set)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Roles"
            label-for="account-roles"
          >
            <b-form-tags
              name="roles"
              placeholder=""
              :value="auth.roles"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Jira Token"
            label-for="account-jiraToken"
          >
            <b-form-input
              v-model="jiraToken"
              name="jiraToken"
              placeholder="(not set)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-button class="mt-1" variant="primary" @click="saveProfile">
            Save</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTags, BFormText, BRow, BCol, BAlert, BCard, BLink,
} from 'bootstrap-vue'
import ButtonAccountManagement from '@/auth/components/ButtonAccountManagement.vue'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTags,
    BRow,
    BCol,
    BAlert,
    BCard,
    BLink,
  },
  data() {
    return {
      avatarFile: null,
      avatarFileResult: this.$store.state.auth.avatarBlobUrl,
      jiraToken: '',
      jobTitle: '',
      firstName: '',
      lastName: '',
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
  },
  mounted() {
    if (this.$store.state.auth.attributes) {
      this.firstName = this.$store.state.auth.firstName
      this.lastName = this.$store.state.auth.lastName
      const attrs = this.$store.state.auth.attributes
      if (Object.hasOwn(attrs, 'jiraToken')) [this.jiraToken] = attrs.jiraToken
      if (Object.hasOwn(attrs, 'jobTitle')) [this.jobTitle] = attrs.jobTitle
    }
  },
  methods: {
    showFileUpload() {
      document.getElementById('fileInput').click()
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        this.avatarFileResult = URL.createObjectURL(e.target.files[0])
        // eslint-disable-next-line prefer-destructuring
        this.avatarFile = e.target.files[0]
      }
    },
    async saveProfile() {
      const result = await this.$coreService.get(`/auth/user/${this.$store.state.auth.id}`)
      const user = result.data
      user.firstName = this.firstName
      user.lastName = this.lastName
      user.attributes = user.attributes || {}
      user.attributes.jiraToken = this.jiraToken
      user.attributes.jobTitle = this.jobTitle
      if (this.avatarFile !== null) {
        const formData = new FormData()
        formData.append('file', this.avatarFile)
        const avatarResponse = await this.$coreService.post(`/auth/user/${user.id}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      const newUserResponse = await this.$coreService.put('/auth/user', user)
      if (newUserResponse) {
        await this.$store.dispatch('auth/getUserData')
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'User Account',
            text: 'Saved User Account Details',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'User Account',
            text: 'Failed Saving User Account Details',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.avatar-link-box {
    display:block; width: 75px; height: 75px; margin: 16px;
}
.avatar-link-box:hover {
    opacity:0.6
}
</style>
