<template>
  <b-card
    title="Change password"
    sub-title="Enter your current password, and enter your new password and confirm it."
    class="mb-6"
  >
    <p>
      <b-link :href="forgotPasswordUrl">
        <small>Forgot Password?</small>
      </b-link>
    </p>
    <b-row>
      <validation-observer ref="loginValidation">
        <b-col sm="12">
          <validation-provider
            #default="{ errors }"
            name="Current Password"
            rules="required"
          >
            <b-form-group label="Current Password">
              <b-input-group
                class="input-group-merge"
                label="Old Password"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="old-password"
                  v-model="oldPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="old-password"
                  placeholder="············"
                />
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <validation-provider
          #default="{ errors }"
          name="New Password"
          vid="confirmation"
          rules="required"
        >
          <b-col sm="12">
            <b-form-group label="New Password">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="new-password"
                  placeholder="············"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="New Password"
          rules="confirmed:confirmation"
        >
          <b-col sm="12">
            <b-form-group label="Confirm New Password">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="new-password-repeat"
                  v-model="newPasswordRepeat"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="new-password-repeat"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </b-row>
    <b-row>
      <b-button class="mt-1" variant="primary" :disabled="!passwordsMatch" @click="changePassword">
        Change Password
      </b-button>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, confirmed } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      required,
      confirmed,
    }
  },
  computed: {
    forgotPasswordUrl() {
      const authUrl = `${window.location.protocol}//auth.${window.location.host}/auth`
      const realm = process.env.VUE_APP_AUTH_KEYCLOAK_REALM
      return `${authUrl}/realms/${realm}/login-actions/reset-credentials?client_id=kompozition-webapp`
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordsMatch() {
      return this.newPassword.length > 0 && this.newPassword === this.newPasswordRepeat
    },
  },
  methods: {
    async changePassword() {
      const userId = this.$store.state.auth.id
      const formData = { old_password: this.oldPassword, new_password: this.newPassword }
      const response = await this.$coreService.put(`/auth/user/${userId}/change-password`, formData)
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Password Changed',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Current password is incorrect',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
